

















import { Component, Vue } from 'vue-property-decorator';
import { ArticleId } from '@/api/api';

@Component({
    name: 'ChargeDetails',
    components: {
    }
})
export default class ChargeDetails extends Vue {

    public visible: boolean = false;
    public title: string = '';
    public ArticleBody: string = '';
    public loading: boolean = false;

    public async open(href) {
        const ContentId = this.getQueryVariable('ContentId', href);
        this.init(ContentId);
    }

    private async init(Article) {
        try {
            this.loading = true;
            this.visible = true;
            const { Data } = await ArticleId(Article);
            this.title = Data.ArticleTitle;
            this.ArticleBody = Data.ArticleBody;
        } catch (error) {
            console.warn(error);
        } finally {
            this.loading = false;
        }
    }

    private getQueryVariable(variable, locationHref) {
        const href = locationHref;
        const query = href.substring(href.indexOf('?') + 1);
        const vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            if (pair[0] === variable) {
            return pair[1];
            }
        }
        return null;
    }

    public close() {
        this.visible = false;
    }
}
