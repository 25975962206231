




















import { Component, Vue } from 'vue-property-decorator';

import { CarrierDeliveryTrack } from '@/api/api';
@Component({
    name: 'OrderQuery',
    components: {}
})
export default class OrderQuery extends Vue {
    public id: any = '';

    public tableData: any = [];

    private mounted() {
        this.id = this.$route.query.id;
        this.init();
    }

    private async init() {
        try {
            const { Data } = await CarrierDeliveryTrack({
                WaybillNo: this.id
            });
            this.tableData = Data;
        } catch (err) {
            console.warn(err);
        }
    }
}
