
















import { Component, Vue, Prop } from 'vue-property-decorator';

// 承运商
@Component({
    name: 'carrierSelect',
    components: {}
})
export default class carrierSelect extends Vue {
    @Prop({ type: [Number, String], default: '' }) public value!:any;

    @Prop({ type: [Object, Array], default: [] }) public options!:any;

    public handleChange(val: any) {
        this.$emit('input', val);
        // eslint-disable-next-line eqeqeq
        const obj = this.options.find((e) => e.CarrierIds == val);
        this.$emit('change', obj);
    }

}
