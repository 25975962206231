
















import { Component, Vue, Prop } from 'vue-property-decorator';

// 运输方式
@Component({
    name: 'TransportType',
    components: {}
})
export default class TransportType extends Vue {
    @Prop({ type: [Number, String], default: '' }) public value!:any;

    @Prop({ type: [Object, Array], default: [] }) public options!:any;

    public handleChange(val: any) {
        this.$emit('input', val);
        this.$emit('change');
    }

    // public get listIcon() {
    //     return (id) => {
    //         let icon = '';
    //         // eslint-disable-next-line eqeqeq
    //         if (id == '1') {
    //             icon = 'iconfont icon-jiyunleixing-kongyun';
    //         }

    //         // eslint-disable-next-line eqeqeq
    //         if (id == '2') {
    //             icon = 'iconfont icon-jiyunleixing-haikuai';
    //         }

    //         // eslint-disable-next-line eqeqeq
    //         if (id == '3') {
    //             icon = 'iconfont icon-jiyunleixing-luyun'; // 陆运
    //         }

    //         // eslint-disable-next-line eqeqeq
    //         if (id == '4') {
    //             icon = 'iconfont icon-jiyunleixing-haiyun';
    //         }
    //         return icon;
    //     };
    // }

}
