import request from '@/utils/request';
import { appConfigModule } from '@/store/modules/appConfig';

// 报价模块 仓库
export const GetWareHouseSelectBoxByShop = (params: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/ShopPrice/GetWareHouseSelectBoxByShop`,
        method: 'GET',
        params
    });
};

// 报价模块目的地
export const GetCountrySelectBoxByShop = (params: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/ShopPrice/GetCountrySelectBoxByShop`,
        method: 'GET',
        params
    });
};

// 报价模块 出货类型
export const GetGoodsTypeSelectBoxByShop = (params: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/ShopPrice/GetGoodsTypeSelectBoxByShop`,
        method: 'GET',
        params
    });
};

// 内容类型查询 栏目列表
export const CategoryGetList = (params?: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/Category/GetList`,
        method: 'GET',
        params
    });
};

/*
获取自提点类型
GET /DeliveryStation/GetStationTypeList
接口ID：58054645
接口地址：https://www.apifox.cn/link/project/1143871/apis/api-58054645
*/
export const GetStationTypeList = (params: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/DeliveryStation/GetStationTypeList`,
        method: 'GET',
        params
    });
};

// 国家
export const CountryList = (params?: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/Country/GetSelectBoxList`,
        method: 'GET',
        params
    });
};

// 省
export const ProvinceList = (params?: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/Province/GetSelectBoxList`,
        method: 'GET',
        params
    });
};

// 市
export const CityList = (params?: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/City/GetSelectBoxList`,
        method: 'GET',
        params
    });
};

// 县
export const AreaList = (params?: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/Area/GetSelectBoxList`,
        method: 'GET',
        params
    });
};

// 国家 自提
export const CarryCountryList = (params?: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/DeliveryStation/GetCountryList`,
        method: 'GET',
        params
    });
};

// 省 自提
export const CarryProvinceList = (params?: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/DeliveryStation/GetProvinceList`,
        method: 'GET',
        params
    });
};

// 市 自提
export const CarryCityList = (params?: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/DeliveryStation/GetCityList`,
        method: 'GET',
        params
    });
};

// 区 自提
export const CarryAreaList = (params?: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/DeliveryStation/GetAreaList`,
        method: 'GET',
        params
    });
};

// 自提点
export const CarryStationList = (params?: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/DeliveryStation/GetStationList`,
        method: 'GET',
        params
    });
};

/*
香港获取自提点列表
GET /DeliveryStation/GetHKStationList
接口ID：58492538
接口地址：https://www.apifox.cn/link/project/987243/apis/api-58492538
*/
export const GetHKStationList = (params?: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/DeliveryStation/GetHKStationList`,
        method: 'GET',
        params
    });
};

/*
店铺包含的线路（初始国家地区--&gt;目的国家地区）
  GET /ShopPrice/GetTransportLine
  接口ID：135203755
  接口地址：https://app.apifox.com/link/project/987243/apis/api-135203755
*/
export const ShopPriceGetTransportLine = (params?: any) => {
    return request({
        url: `${appConfigModule.appConfig.vueHkApi}/CMS/Page/ShopPrice/GetTransportLine`,
        method: 'GET',
        params
    });
};
