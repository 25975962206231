















































import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
import { ShopPriceGetTransportLine } from '@/api/select';
import { UserModule } from '@/store/modules/user';
@Component({
    name: 'LineSelectCardIndex',
    components: {}
})
export default class LineSelectCardIndex extends Vue {
    @Ref('elSelect') private readonly elSelectRef;

    @Prop({ type: [Number, String], default: '' }) public value!:any;

    @Prop({ type: Number, default: 0 }) public lineType: 0 | 1 | 2;

    public options: Array<any> = [];

    public loading: boolean = false;

    private mounted() {
        this.init();
    }

    private async init() {
        try {
            this.loading = true;
            const { Data } = await ShopPriceGetTransportLine({
                lineType: this.lineType
            });
            // Data.map((item) => {
            //     item.CustomCountryNames = item.CountryNames.replace('|', '寄');
            //     return item;
            // });

            this.options = Data;
            // 如果已设置偏好，默认加载以设置的
            if (this.ClientPreference && this.ClientPreference.CountryIds) {
                this.handleChange(this.ClientPreference.CountryIds);
            }
        } catch (err) {
            console.warn(err);
            this.handleChange(null);
        } finally {
            this.loading = false;
        }
    }

    public handleChange(val: any) {
        this.$emit('input', val);
        this.$emit('change');
    }

    public penetrate() {
        this.elSelectRef.toggleMenu();
    }

    public get TextLable() {
        const value = this.options.find((e) => e.CountryIds === this.value);
        const label: any = {};
        if (value) {
            const row = value.CountryNames.split('|');
            label.key1 = row[0];
            label.key2 = row[1];
        }
        return label;
    }

    private get ClientPreference() {
        return UserModule.userData.ClientPreference;
    }
}
